// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-home-home-page-tsx": () => import("./../src/components/home/HomePage.tsx" /* webpackChunkName: "component---src-components-home-home-page-tsx" */),
  "component---src-components-about-about-page-tsx": () => import("./../src/components/about/AboutPage.tsx" /* webpackChunkName: "component---src-components-about-about-page-tsx" */),
  "component---src-templates-sermon-index-js": () => import("./../src/templates/sermon-index.js" /* webpackChunkName: "component---src-templates-sermon-index-js" */),
  "component---src-templates-sermon-entry-js": () => import("./../src/templates/sermon-entry.js" /* webpackChunkName: "component---src-templates-sermon-entry-js" */)
}

